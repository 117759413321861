import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PostWrapper from "../../components/postWrapper"
import PostList from "../../components/postList"
import PostSidebar from "../../components/postSidebarFilter"
import Pagination from "../../components/pagination"

export const query = graphql`
  query ($skip: Int, $limit: Int) {
    allSanityBlogInsight(sort: {fields: publishDate, order: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
          id
          title
          slug { current }
          category {
            title
            slug { current }
          }
          _rawThumbnail(resolveReferences: {maxDepth: 10})
          excerpt
        }
      }
    }
    sanityBlogSettings {
      heroTitleInsights
      descriptionInsights
      openGraphImageInsights {
        asset {
          url
        }
      }
    }
  }
`

const InsightIndex = (props) => {
  const settings = props.data.sanityBlogSettings
  const blogs = props.data.allSanityBlogInsight.edges
  const { currentPage, blogInsightPages } = props.pageContext
  const image = settings?.openGraphImageInsights?.asset !== null ? settings?.openGraphImageInsights?.asset.url : null

  return (
    <Layout theme="light">
      <SEO
        title={settings.heroTitleInsights}
        description={settings.descriptionInsights}
        image={image}
      />
      <Hero heroTitle={settings.heroTitleInsights} theme="light" graph="radar" />
      <PostWrapper>
        <PostList items={blogs} theme="brandLight" type="blog" alt={props.alt} />
        <PostSidebar type="blogInsight" />
      </PostWrapper>
      {blogInsightPages > 1 && <Pagination base="blog/" currentPage={currentPage} totalPages={blogInsightPages} />}
    </Layout>
  )
}

export default InsightIndex
